/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
// @ngInject
function states($stateProvider) {
  $stateProvider
    .state('legal', {
      parent: 'root',
      redirectTo: '.disclosure-dictionary',
      url: '',
      template: '<ui-view>',
      resolve: {
        module: /* @ngInject */ function($ocLazyLoad) {
          return import(/* webpackChunkName: "acp.section.disclosure-dictionary" */ 'sections/disclosure-dictionary').then(
            $ocLazyLoad.loadModule
          );
        }
      }
    })
    .state('legal.disclosure-dictionary', {
      url: '/disclosure-dictionary',
      templateUrl:
        'sections/disclosure-dictionary/templates/acp-disclosure-dictionary.ng.html'
    });
}

export default states;
